import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  GET_FILTER_ASSIGNEE,
  GET_FILTER_PROJECTS,
  VIEW_ALL_ISSUES,
  VIEW_ALL_STASTUS,
} from "services/endPoints";
import { privateRequest } from "services/privateRequest";

const typeList = [
  {
    lebal: "Epic",
    value: "EPIC",
  },
  {
    lebal: "Story",
    value: "STORY",
  },
  {
    lebal: "Task",
    value: "TASK",
  },
  {
    lebal: "Bug",
    value: "BUG",
  },
];

const priorityList = [
  {
    lebal: "Blocker",
    value: "BLOCKER",
  },
  {
    lebal: "Highest",
    value: "HIGHEST",
  },
  {
    lebal: "High",
    value: "HIGH",
  },
  {
    lebal: "Low",
    value: "LOW",
  },
  {
    lebal: "Lowest",
    value: "LOWEST",
  },
];

const filterInitialState = {
  list: [],
  status: "idle", // 'idle' | 'loading' | 'error'
  totalCount: 0,
};

const initialState = {
  issues: {
    list: [],
    status: "idle", // 'idle' | 'loading' | 'error'
    totalCount: 0,
  },
  filters: {
    project: filterInitialState,
    status: filterInitialState,
    assignee: filterInitialState,
    reporter: filterInitialState,
    type: {
      list: typeList,
    },
    priority: {
      list: priorityList,
    },
  },
};

const getParsedStages = (stages) => {
  let parasedStages = [];

  stages.forEach((stage) => {
    const newParsed = JSON.parse(stage);
    parasedStages = [...parasedStages, ...newParsed];
  });

  return parasedStages;
};

// Helper function to handle the export logic
const downloadExportFile = (response) => {
  const contentDisposition = response.headers['content-disposition'];
  const fileName = contentDisposition ? contentDisposition.split('filename=')[1] : 'issues.xlsx';
  const url = window.URL.createObjectURL(new Blob([response?.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const getAllIssues = createAsyncThunk(
  "viewAllIssue/getAllIssues",
  async function (
    {
      fields = [],
      pageNo,
      pageSize,
      projectId = [],
      issueTypes = [],
      priority = [],
      reporters = [],
      assignee = [],
      stage = [],
      startDate = "",
      endDate = "",
      search = "",
      isExportIssue = false
    },
    { rejectWithValue, signal }
  ) {
    const body = {
      ...(fields.length ? { fields } : {}),
      ...(projectId.length ? { projectId } : {}),
      ...(issueTypes.length ? { issueTypes } : {}),
      ...(priority.length ? { priority } : {}),
      ...(reporters.length ? { reporters } : {}),
      ...(assignee.length ? { assignee } : {}),
      ...(stage.length ? { stage: getParsedStages(stage) } : {}), // TODO: check with backend is it added in backend side or nots
      ...(startDate ? { startDate } : {}),
      ...(endDate ? { endDate } : {}),
      ...(search ? { search } : {}),
      ...(pageNo ? { pageNo } : {}),
      ...(pageSize ? { pageSize } : {}),
      ...(isExportIssue ? {export : true} : {export : false})
    };

    try {
      if(isExportIssue) {
        const exportResponse = await privateRequest.post(
          `${VIEW_ALL_ISSUES}?page-no=${pageNo}&page-size=${pageSize}&export=${isExportIssue}`,
          body, {signal, responseType : "blob"}
        );
      downloadExportFile(exportResponse);
      return;
      }
      else{
        const response = await privateRequest.post(
          `${VIEW_ALL_ISSUES}?page-no=${pageNo}&page-size=${pageSize}`,
        body, {signal}
      );
      return {
        data: response?.data?.data?.data || [],
        totalCount: response?.data?.data?.count?.total || 0,
      };
    }
    } catch (error) {
      rejectWithValue(error);
      toast.error(error?.data?.message || "Some thing went wrong");
    }
  }
);

export const getFilterProjectsOptions = createAsyncThunk(
  "viewAllIssue/getFilterProjectsOptions",
  async function ({ search, pageNo, pageSize }, { rejectWithValue }) {
    try {
      const response = await privateRequest.get(
        `${GET_FILTER_PROJECTS}?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}`
      );

      const data =
        response?.data?.data?.data?.map((option) => ({
          ...option,
          lebal: option.name,
          value: option.id,
        })) || [];

      return {
        data,
        totalCount: response?.data?.data?.count?.total || 0,
      };
    } catch (error) {
      rejectWithValue(error);
      toast.error(error?.data?.message || "Some thing went wrong");
    }
  }
);

export const getFilterStatusOptions = createAsyncThunk(
  "viewAllIssue/getFilterStatusOptions",
  async function (
    { projectId = [], search, pageNo, pageSize },
    { rejectWithValue }
  ) {
    try {
      let url = `${VIEW_ALL_STASTUS}?page-no=${pageNo}&page-size=${pageSize}`;

      if (projectId.length) {
        url = `${url}&project-id=${projectId}`;
      }

      if (search) {
        url = `${url}&search=${search}`;
      }

      const response = await privateRequest.get(url);

      const data =
        response?.data?.data?.data?.map((option) => ({
          ...option,
          lebal: option.stageName,
          value: option.ids,
        })) || [];

      return {
        data,
        totalCount: response?.data?.data?.count?.total || 0,
      };
    } catch (error) {
      rejectWithValue(error);
      toast.error(error?.data?.message || "Some thing went wrong");
    }
  }
);

export const getFilterAssigneeOptions = createAsyncThunk(
  "viewAllIssue/getFilterAssigneeOptions",
  async function (
    { projectId = [], search, pageNo, pageSize, type = "assignee" },
    { rejectWithValue }
  ) {
    try {
      let url = `${GET_FILTER_ASSIGNEE}?page-no=${pageNo}&page-size=${pageSize}`;

      if (projectId.length) {
        url = `${url}&project-id=${projectId}`;
      }

      if (search) {
        url = `${url}&search=${search}`;
      }

      const response = await privateRequest.get(url);

      const data =
        response?.data?.data?.data?.map((option) => ({
          ...option,
          lebal: option.username,
          value: option.userId,
        })) || [];

      return {
        data,
        totalCount: response?.data?.data?.count?.total || 0,
        type,
      };
    } catch (error) {
      rejectWithValue(error);
      toast.error(error?.data?.message || "Some thing went wrong");
    }
  }
);

export const viewAllIssueSlcie = createSlice({
  name: "viewAllIssue",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllIssues.pending, (state, action) => {
      if(!action.meta.arg?.isExportIssue) {
      state.issues.status = "loading";
      }
    });
    builder.addCase(getAllIssues.fulfilled, (state, action) => {
      if(!action.meta.arg?.isExportIssue) {
        state.issues.list = action.payload?.data;
        state.issues.totalCount = action.payload?.totalCount;
      }
      state.issues.status = "idle";
    });
    builder.addCase(getAllIssues.rejected, (state, action) => {
      state.issues.status = "error";
    });
    builder.addCase(getFilterProjectsOptions.pending, (state) => {
      state.filters.project.status = "loading";
    });
    builder.addCase(getFilterProjectsOptions.fulfilled, (state, action) => {
      state.filters.project.status = "idle";
      state.filters.project.list = action.payload.data;
      state.filters.project.totalCount = action.payload.totalCount;
    });
    builder.addCase(getFilterProjectsOptions.rejected, (state, action) => {
      state.filters.project.status = "error";
    });
    builder.addCase(getFilterStatusOptions.pending, (state) => {
      state.filters.status.status = "loading";
    });
    builder.addCase(getFilterStatusOptions.fulfilled, (state, action) => {
      state.filters.status.status = "idle";
      state.filters.status.list = action.payload.data;
      state.filters.status.totalCount = action.payload.totalCount;
    });
    builder.addCase(getFilterStatusOptions.rejected, (state, action) => {
      state.filters.status.status = "error";
    });
    builder.addCase(getFilterAssigneeOptions.pending, (state, action) => {
      if (action?.meta?.arg?.type === "assignee")
        state.filters.assignee.status = "loading";
      else state.filters.reporter.status = "loading";
    });
    builder.addCase(getFilterAssigneeOptions.fulfilled, (state, action) => {
      if (action.payload?.type === "assignee") {
        state.filters.assignee.status = "idle";
        state.filters.assignee.list = action.payload.data;
        state.filters.assignee.totalCount = action.payload.totalCount;
      } else {
        state.filters.reporter.status = "idle";
        state.filters.reporter.list = action.payload.data;
        state.filters.reporter.totalCount = action.payload.totalCount;
      }
    });
    builder.addCase(getFilterAssigneeOptions.rejected, (state, action) => {
      if (action.payload?.type === "assignee")
        state.filters.assignee.status = "error";
      else state.filters.reporter.status = "error";
    });
  },
});

export const { setIsLoading, setLandingPageData, setStatusForFilter } =
  viewAllIssueSlcie.actions;
export default viewAllIssueSlcie.reducer;
